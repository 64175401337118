import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './sample.module.css';

import Blog from '../../components/Blog';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import Icon from '../../components/Icons/Icon';
import Button from '../../components/Button';

const SamplePage = (props) => {
  return (
    <Layout>
      <div className={styles.root}>
        <Container>
          <div className={styles.blogContainer}>
            <Blog
              category={'SELF IMPROVEMENT'}
              title={'Rich Dad Poor Dad: What the Rich Teach Their Kids About Money That the Poor and Middle Class Do Not!'}
              image={'/books/rich_dad_poor_dad_0.jpg'}
              alt={''}
            >
              <div className={styles.content}>
              <br></br>
              <h2>ENG</h2>
              <br></br>
              <p className={styles.excerpt}>

              "Rich Dad Poor Dad: What the Rich Teach Their Kids About Money That the Poor and Middle Class Do Not!" is a best-selling personal finance book written by Robert Kiyosaki. The book is based on Kiyosaki's personal experiences and observations, as well as the lessons he learned from his two "dads." One dad was his biological father, who was highly educated but struggled financially. The other dad was his friend's father, who was a successful entrepreneur and investor.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              The book emphasizes the importance of financial education and how it can lead to financial independence. Kiyosaki argues that schools do not teach financial education, which is why many people struggle with money. He believes that in order to be successful financially, people need to understand the difference between assets and liabilities. Assets are things that put money into your pocket, such as rental property or a stock that pays dividends. Liabilities are things that take money out of your pocket, such as a car payment or a mortgage payment. Kiyosaki argues that the rich focus on acquiring assets and minimizing liabilities, while the poor and middle class focus on acquiring liabilities.   
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kiyosaki also talks about the importance of taking risks and learning from failures. He believes that the fear of failure often holds people back from taking risks and achieving success. He argues that it is important to learn from failures and to keep trying until you succeed.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Another important concept in the book is the idea of passive income. Kiyosaki believes that passive income is the key to financial freedom. Passive income is money that you earn without actively working for it, such as rental income or dividend income from stocks. Kiyosaki argues that people should focus on building passive income streams in order to achieve financial independence.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              The book also discusses the importance of entrepreneurship and how it can lead to financial success. Kiyosaki believes that starting your own business is a great way to create passive income and build wealth. He argues that successful entrepreneurs are able to see opportunities where others see obstacles, and they are willing to take risks in order to achieve their goals.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Overall, "Rich Dad Poor Dad" is a thought-provoking book that challenges traditional beliefs about money and success. Kiyosaki's personal stories and lessons provide valuable insights into how the rich think and approach money. The book emphasizes the importance of financial education, taking risks, and building passive income streams. It is a must-read for anyone who wants to achieve financial independence and build wealth.  

              </p>
              </div>

              <div className={styles.content}>
              <br></br>
              <h2>LV</h2>
              <br></br>
              <p className={styles.excerpt}>

              "Bagātais tētis, nabagais tētis: ko bagātie māca saviem bērniem par naudu, ko nemāc mazāk priviliģētie un vidusšķira!" ir vislabāk pārdotā personīgo finanšu grāmata, ko sarakstījis Roberts Kijosaki. Grāmata ir balstīta uz Kiyosaki personīgo pieredzi un novērojumiem, kā arī mācībām, ko viņš guva no saviem diviem "tētiem". Viens tētis bija viņa bioloģiskais tēvs, kurš bija augsti izglītots, bet cieta finansiālas grūtības. Otrs tētis bija viņa drauga tēvs, kurš bija veiksmīgs uzņēmējs un investors.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Grāmatā uzsvērta finanšu izglītības nozīme un tas, kā tā var novest pie finansiālās neatkarības. Kiyosaki apgalvo, ka skolās nemāca finanšu izglītību, tāpēc daudzi cilvēki cīnās ar naudu. Viņš uzskata, ka, lai gūtu panākumus finansiāli, cilvēkiem ir jāsaprot atšķirība starp aktīviem un saistībām. Aktīvi ir lietas, kas ieliek naudu jūsu kabatā, piemēram, īres īpašums vai akcijas, kas maksā dividendes. Saistības ir lietas, kas izņem naudu no kabatas, piemēram, automašīnas maksājums vai hipotēkas maksājums. Kiyosaki apgalvo, ka bagātie koncentrējas uz aktīvu iegādi un saistību samazināšanu, bet nabagie un vidusšķira koncentrējas uz saistību iegūšanu.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kiyosaki arī runā par to, cik svarīgi ir riskēt un mācīties no neveiksmēm. Viņš uzskata, ka bailes no neveiksmes bieži attur cilvēkus riskēt un gūt panākumus. Viņš apgalvo, ka ir svarīgi mācīties no neveiksmēm un turpināt mēģināt, līdz izdodas.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Vēl viens svarīgs jēdziens grāmatā ir pasīvo ienākumu ideja. Kiyosaki uzskata, ka pasīvie ienākumi ir finansiālās brīvības atslēga. Pasīvie ienākumi ir nauda, ko jūs nopelnāt, aktīvi nestrādājot, piemēram, īres ienākumi vai dividendes no akcijām. Kiyosaki apgalvo, ka cilvēkiem ir jākoncentrējas uz pasīvo ienākumu plūsmu veidošanu, lai sasniegtu finansiālo neatkarību.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Grāmatā aplūkota arī uzņēmējdarbības nozīme un tas, kā tā var novest pie finansiāliem panākumiem. Kiyosaki uzskata, ka sava biznesa uzsākšana ir lielisks veids, kā radīt pasīvus ienākumus un vairot bagātību. Viņš apgalvo, ka veiksmīgi uzņēmēji spēj saskatīt iespējas tur, kur citi redz šķēršļus, un ir gatavi riskēt, lai sasniegtu savus mērķus.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kopumā "Bagātais tētis, nabagais tētis" ir pārdomas rosinoša grāmata, kas izaicina tradicionālos uzskatus par naudu un panākumiem. Kiyosaki personīgie stāsti un mācības sniedz vērtīgu ieskatu tajā, kā bagātie domā un tuvojas naudai. Grāmatā uzsvērta finanšu izglītības, riska uzņemšanās un pasīvo ienākumu plūsmu veidošanas nozīme. Tā ir obligāta lasāmviela ikvienam, kurš vēlas iegūt finansiālu neatkarību un vairot bagātību.

              </p>
              </div>
            </Blog>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SamplePage;
